import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import Logo from 'src/components/Logo';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: '4em'
  },
  listItem: {
    borderRadius: "0.25em",
    maxHeight: '2em',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  }
}));


const TopBar = ({ languages, surveyId, ro, lang, token, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(lang);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, lang, ro) => {
    setSelectedLang(lang);
    setAnchorEl(null);
    navigate('/' + token + '?l=' + lang, { replace: true });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //survey ids for logo display
  let ARCSurveys = [32,34,35,36,37,38,102,103,104,105,106,116,141,147,153,156]
  let logoSurveys = [7,8,32,34,35,36,37,38,76,78,109,110,102,103,104,105,106,116,118,119,81,120,121,122,123,124,125,126,127,128,129,130,131,132,
    133,134,135,136,137,138,139,141,147,149,151,150,152,153,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169]
  let tanfeethSurveys = [109,110]
  let ARBSurveys = [81,118,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,151,150,157,162,163,164,165,166,167,168,169];
  let emkanSurveys = [152,155];
  let tuderSurveys = [119,160];
  let NeoSurveys = [149,158];
  let ejadaSurveys = [159];
  let atmaalSurveys = [161]
  let color = '#221afb';
  let height;
  let logoPath = 'static/logo.png'
  if(ARCSurveys.includes(surveyId)){
    color = 'white'
    logoPath = 'static/logo_arc.png'
  } else if(tanfeethSurveys.includes(surveyId)){
    color = 'white'
    logoPath = 'static/logo_tanfeeth.png'
  } else if(tuderSurveys.includes(surveyId)){
    color = 'white'
    height = 80
    logoPath = 'static/logo_tuder.png'
  } else if(NeoSurveys.includes(surveyId)){
    color = 'white'
    height = 50
    logoPath = 'static/logo_neo.png'
  } else if(ARBSurveys.includes(surveyId)){
    color = 'white'
    height = 50
    logoPath = 'static/logo_blue.jpg'
  } else if(emkanSurveys.includes(surveyId)){
    color = 'white'
    height = 50
    logoPath = 'static/logo_emkan.png'
  } else if(ejadaSurveys.includes(surveyId)){
    color = 'white'
    height = 50
    logoPath = 'static/logo_ejada.png'
  }else if(atmaalSurveys.includes(surveyId)){
    color = 'white'
    height = 50
    logoPath = 'static/logo_atmaal.png'
  }
  return (
    <AppBar
      style={{ background: color }}
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      {languages && languages.length > 0 &&
        < Toolbar className={classes.toolbar}>
          <RouterLink to="">
            { logoSurveys.includes(surveyId) ? <Logo path={logoPath} height={height} /> : '' }
          </RouterLink>
          {(surveyId === 76 || surveyId === 78) && <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle2" style={{whiteSpace: 'pre-line', color:'white'}}>Employee Engagement Survey</Typography>
          </Box>}
          <Box flexGrow={1} />
          <Box>
            <List component="nav">
              <ListItem
                className={classes.listItem}
                button
                onClick={handleClickListItem}
              >
                <ListItemText secondary={<Typography variant="body1">{languages.filter(language => language.code.includes(selectedLang)).map(obj => obj.label)}</Typography>} />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {languages.map((language, index) => (
                <MenuItem
                  key={index}
                  selected={language.code === selectedLang}
                  onClick={(event) => handleMenuItemClick(event, language.code, ro)}
                >
                  {language.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      }
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
